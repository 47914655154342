#about-us-text {
    position: absolute;
    top: 223px;
    left: 257px;
    width: 728px;
    height: 449px;
    text-align: left;
    font-family : NotoSans;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0px;
    color: #050727;
    opacity: 1;
}

#Seigaiha_Accent_gn {
    position: absolute;
    width: 405.584px;
    height: 352.625px;
    left: 73.5%;
    top: 274px;
    overflow: hidden;
}

.mobile-about-us {
    position: absolute;
    min-height: 100vh;
}

.mobile-about-us::before {
    position: absolute;
    display: block;
    content: ' ';
    width: 100%;
    height: 100%;
    background-image: image-set(
        url("/public/Group_949.png") 1x,
        url("/public/Group_949@2x.png") 2x);
    background-position: 100% 98%;
    background-size: 50% auto;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    opacity: 100%;
}

.mobile-about-us-header {
    font: normal normal normal 18pt Times;
    color: #120F83;
    text-align: left;
    margin-left: 10%;
    margin-top: 10%;
    margin-bottom: 8%;
}

.mobile-about-us-subtext {
    font: normal normal normal 14pt NotoSans;
    color: #000000;
    text-align: left;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 25%;
}

.mobile-footer-watermark {
    font: normal normal 700 8pt NotoSans;
    color: #BDBDBF;
    text-align: center;
}